import Swiper from 'swiper';
import {Navigation, Thumbs, FreeMode} from 'swiper/modules';

Swiper.use([Navigation, Thumbs, FreeMode]);

window.addEventListener('load', () => {
    const thumbsSlider = document.querySelector('.thumbsSlider');
    const mainSlider = document.querySelector('.mainSlider');

    if (thumbsSlider && mainSlider) {
        const swiper = new Swiper(thumbsSlider, {
            modules: [FreeMode],
            spaceBetween: 20,
            slidesPerView: 4,
            freeMode: true,
            watchSlidesProgress: true,
            breakpoints: {
                1366: {
                    spaceBetween: 40,
                },
            },
        });

        window.swiper = new Swiper(mainSlider, {
            spaceBetween: 0,
            modules: [Navigation, Thumbs],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            thumbs: {
                swiper,
            },
        });
    }
});
