/* eslint-disable */
import jQuery from 'jquery';
/*
 * jQuantity
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.1 – Initial version.
 */

// throw exeption if no jQuery
if (!jQuery) {
    throw new Error("jQuantity requires jQuery");
}

;(function ($, window, document, undefined) {

    // our plugin constructor
    var jQuantity = function (elem) {
        var me = this;

        me.$elem = $(elem);

    };

    // the plugin prototype
    jQuantity.prototype = {
        defaults: {
            classes: {
                qty: '#jQty',
            }
        },

        init: function () {
            var me = this;

            // Introduce defaults that can be extended either globally or using an object literal.
            me.config = $.extend({}, me.defaults, me.options, me.$elem.get(0).dataset);

            me.$select = $('[data-jselect="true"]', me.$elem).data('jSelect');
            me.$left = $('[data-qty-left]', me.$elem);
            me.$right = $('[data-qty-right]', me.$elem);

            me.setDisabled();

            me.registerEvents();

            return me;
        },

        setDisabled: function() {
            var me = this;

            if(parseInt(me.$select.value) === 1) {
                me.$left.prop("disabled", true);
            }

            if(parseInt(me.$select.value) === 19) {
                me.$right.prop("disabled", true);
            }
        },

        /**
         * Register all plugin events.
         */
        registerEvents: function() {
            var me = this;

            me.$left.on('click', $.proxy(function(event){
                if(parseInt(me.$select.value) > 1) {
                    me.$select.setValue(parseInt(me.$select.value) - 1);
                    me.setDisabled();
                }
            }, me));

            me.$right.on('click', $.proxy(function(event){
                if(parseInt(me.$select.value) < 20) {
                    me.$select.setValue(parseInt(me.$select.value) + 1);
                    me.setDisabled();
                }
            }, me));
        },

    };

    $.fn.jQuantity = function () {
        return this.each(function () {
            var plugin = new jQuantity(this).init();
            $(this).data('jQuantity', plugin);
        });
    };

    /**
     * Self ini plugin.
     */
    $(document).ready(function () {
        $("[data-qty='true']").jQuantity();
    });

})(jQuery, window, document);
