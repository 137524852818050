/* eslint-disable */
import jQuery from 'jquery';
/*
 * jModal
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.1 – Initial version.
 */

// throw exeption if no jQuery
if (!jQuery) {
    throw new Error("jModal requires jQuery");
}

;(function ($, window, document, undefined) {

    // our plugin constructor
    var jModal = function (elem) {
        var me = this;

        me.$elem = $(elem);

    };

    // the plugin prototype
    jModal.prototype = {
        defaults: {
            overlay: true,
            closeClass: 'modal--close',
            activeClass: 'is--active',
            overlayclass: 'modal--overlay',
            overlayactiveclass: 'has--modal-overlay'
        },


        init: function () {
            var me = this;

            // Introduce defaults that can be extended either globally or using an object literal.
            me.config = $.extend({}, me.defaults, me.options, me.$elem.get(0).dataset);

            // append overlay
            if (me.config.overlay) {
                me.appendOverlay();
            }

            // close button
            me.$close = me.$elem.find('.' + me.config.closeClass);

            // find open buttons
            me.open = $('body').find('[data-open-modal="' + me.$elem.get(0).id + '"]');

            // register events
            $('body').find('[data-open-modal="' + me.$elem.get(0).id + '"]').on('click.jmodal', $.proxy(function (event) {
                event.preventDefault();
                me.showModal();
            }, me));

            me.$close.on('click.jmodal', $.proxy(function (event) {
                event.preventDefault();
                me.hideModal();
            }, me));

            return me;
        },

        /**
         * Open canvas
         */
        showModal: function () {
            var me = this;

            me.$elem.addClass(me.config.activeClass);

            $('body').addClass(me.config.overlayactiveclass);

            if (me.config.overlay) {
                me.overlay.addClass(me.config.overlayactiveclass);
            }

            me.$elem.trigger("onShowModal", [me]);
        },

        /**
         * Close Canvas
         */
        hideModal: function () {
            var me = this;

            me.$elem.removeClass(me.config.activeClass);

            $('body').removeClass(me.config.overlayactiveclass);

            if (me.config.overlay) {
                me.overlay.removeClass(me.config.overlayactiveclass);
            }

            me.$elem.trigger("onHideModal", [me]);
        },


        /**
         * Append overlay to document.
         */
        appendOverlay: function () {
            var me = this;

            me.overlay = $('<div/>').addClass(me.config.overlayclass);

            $('body').append(me.overlay);

            me.overlay.on('click.jmodal', $.proxy(function () {
                me.hideModal();
            }, me));

        },
    };

    $.fn.jModal = function () {
        return this.each(function () {
            var plugin = new jModal(this).init();
            $(this).data('jModal', plugin);
        });
    };

    /**
     * Self ini plugin.
     */
    $(document).ready(function () {
        $("[data-modal='true']").jModal();
    });

})(jQuery, window, document);
