/* eslint-disable */
import jQuery from 'jquery';
/*
 * jQuiz – Step based question quiz to detetct something.
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.1 – Initial version.
 *
 * TODO: Rest and Prev Button, result tpl!
 */

if (!jQuery) { throw new Error("jQuiz requires jQuery"); }

;(function( $, window, document, undefined ){

    // our plugin constructor
    var jQuiz = function( elem, options ){
        var me = this;

        me.elem = elem;
        me.$elem = $(elem);
        me.options = options;
    };

    // the plugin prototype
    jQuiz.prototype = {
        defaults: {
            questions: [],
            answers: [],
            hide: 'is--hidden',
            active: 'is--active',
            jumpOver: false
        },

        init: function () {
            var me = this;

            me.config = $.extend({}, me.defaults, me.options, me.$elem.get(0).dataset);

            if(me.config.questions.length === 0 || me.config.answers.length === 0) {
                throw new Error("jQuiz requires questions and answers!");
            }

            me.currenIndex = 0;
            me.stepValues = {};
            me.finals = [];
            me.finalKeys = [];

            me.currentKey = "";
            me.nextKey = "";
            me.prevKey = "";
            me.max = me.config.questions.length;

            // elements
            me.$step = $('.quiz--step', me.$elem);
            me.$ation = $('.quiz--action', me.$elem);
            me.$title = $('.quiz--title', me.$elem);
            me.$elements = $('.quiz--elements', me.$elem);
            me.$result = $('.quiz--result', me.$elem);
            me.$prev = $('.quiz--prev', me.$elem);
            me.$reset = $('.quiz--reset', me.$elem);
            me.$finalReset = $('.quiz--final-reset', me.$elem);
            me.$next = $('.quiz--next', me.$elem);
            me.$alerts = $('.quiz--action-alerts', me.$elem);
            me.$resultContent = $('.quiz--result-content', me.$elem);

            // register all events
            me.registerEvents();

            // set startup keys
            me.setKeys();

            // init first question
            me.initQuestion();

            me.$elem.addClass('is--ini');
            return me;
        },

        /**
         * Init new questions.
         */
        initQuestion: function() {
            var me = this;

            me.currentQuestion = me.config.questions[me.currenIndex];
            // me.currentKey = me.currentQuestion.key;
            if(typeof me.stepValues[me.currentKey] === "undefined") me.stepValues[me.currentKey] = [];

            me.$elements.empty();
            me.$alerts.empty();
            me.$title.text(me.currentQuestion.name);


            if(me.config.jumpOver === true && me.finals.length === 1) {
                e.evaluate();
                return;
            }

            me.$elements.attr('data-type', me.currentQuestion.type);

            if(me.currentQuestion.type === "range") {
                me.$elements.attr({
                    'data-from': me.currentQuestion.from,
                    'data-to': me.currentQuestion.to
                });
            } else {
                me.$elements.attr({
                    'data-from': '',
                    'data-to': ''
                });
            }

            if(me.finalKeys.length === 0 || me.currenIndex === 0 || Object.keys(me.stepValues).length - 1 > me.currenIndex) {

                me.$elements.attr('data-length', me.currentQuestion.values.length);

                for(var i = 0 ; i < me.currentQuestion.values.length; i++){
                    /*me.$elements.append(($("<label />").text(me.currentQuestion.values[i]).prepend($("<input />").attr({
                        "type" : 'checkbox',
                        "name" : me.currentQuestion.key + "[]",
                        "value": me.currentQuestion.values.indexOf(me.currentQuestion.values[i])
                    }).prop('checked', me.inArray(me.currentQuestion.values.indexOf(me.currentQuestion.values[i]), me.stepValues[me.currentKey])))));

                    if(me.inArray(me.currentQuestion.values.indexOf(me.currentQuestion.values[i]), me.stepValues[me.currentKey])) {
                        me.$elements.last('label').prop('checked');
                        console.log(me.$elements.last('label'));
                    }*/

                    me.addButton(
                        me.currentQuestion.values[i],
                        me.currentQuestion.key,
                        me.currentQuestion.values.indexOf(me.currentQuestion.values[i]),
                        me.inArray(me.currentQuestion.values.indexOf(me.currentQuestion.values[i]), me.stepValues[me.currentKey]),
                        me.config.questions[me.currenIndex].type
                    );
                }
            } else {

                // sort
                if(me.currentQuestion.sortDir === 'DESC') {
                    me.finalKeys.sort();
                } else {
                    me.finalKeys.sort().sort(function(a, b){return a-b});
                }

                me.$elements.attr('data-length', me.finalKeys.length);

                if(me.finalKeys.length === 1) {
                    if(me.currenIndex + 1 < me.config.questions.length) {
                        me.currenIndex++;
                        me.setKeys();

                        me.getRelatedKeys();
                        me.initQuestion();
                    } else {
                        me.evaluate();
                    }
                }

                for(var i = 0; i < me.finalKeys.length; i++){
                    /*me.$elements.append(($("<label />").text(me.config.questions[me.currenIndex].values[me.finalKeys[i]]).prepend($("<input />").attr({
                        "type" : 'checkbox',
                        "name" : me.currentQuestion.key + "[]",
                        "value": me.currentQuestion.values.indexOf(me.config.questions[me.currenIndex].values[me.finalKeys[i]])
                    }))));*/

                    me.addButton(
                        me.config.questions[me.currenIndex].values[me.finalKeys[i]],
                        me.currentQuestion.key,
                        me.currentQuestion.values.indexOf(me.config.questions[me.currenIndex].values[me.finalKeys[i]]),
                        false,
                        me.config.questions[me.currenIndex].type
                    );

                }
            }
        },

        addButton: function(text, name, value, checked, type){
            var me = this;

            var t = 'checkbox';
            if(type === 'range' || type === 'radio') t = 'radio';

            var btn = ($("<label />").html("<span>"+text+"</span>").prepend($("<input />").attr({
                "type" : t,
                "name" : name,
                "value": value
            }).prop('checked', checked)));

            if(checked === true) {
                btn.addClass('is--checked');
            }

            me.$elements.append(btn);
        },

        /**
         *
         * @param value
         */
        setValue: function(value) {
            var me = this;
            me.stepValues[me.currentQuestion.key].push(parseInt(value));
            me.$alerts.empty();
        },

        /**
         *
         * @param value
         */
        removeValue: function(value) {
            var me = this;
            me.stepValues[me.currentQuestion.key].pop(parseInt(value));
        },

        /**
         * Register all plugin events.
         */
        registerEvents: function() {
            var me = this;

            /* append */
            me.$elem.on('change.jquiz', 'input', $.proxy(function(event){

                if(event.target.type === 'radio') {

                    var elem = $('input', me.$elements);

                    for(var i = 0; i < elem.length; i++) {
                        me.removeValue($(elem[i]).value);
                        $(elem[i]).parent().removeClass('is--checked');
                    }

                    me.setValue(event.target.value);
                    $(event.target).parent().addClass('is--checked');
                } else {

                    if(event.target.checked === true) {
                        me.setValue(event.target.value);
                        $(event.target).parent().addClass('is--checked');
                    } else {
                        me.removeValue(event.target.value);
                        $(event.target).parent().removeClass('is--checked');
                    }
                }

            }, me));

            /* next */
            me.$elem.on('click.jquiz', '.quiz--next', $.proxy(function(event){

                if(me.stepValues[me.currentQuestion.key].length === 0) {
                    me.$alerts.text(me.currentQuestion.error);
                } else {
                    if(me.currenIndex + 1 < me.config.questions.length) {
                        me.currenIndex++;
                        me.setKeys();

                        me.getRelatedKeys();
                        me.initQuestion();
                    } else {
                        me.evaluate();
                    }
                }
            }, me));

            /* prev */
            me.$elem.on('click.jquiz', '.quiz--prev', $.proxy(function(event){
                if(me.currenIndex - 1 >= 0) {
                    me.currenIndex--;
                    me.setKeys();

                    // me.getRelatedKeys();
                    me.initQuestion();
                } else {
                    me.evaluate();
                }
            }, me));

            /* reset */
            me.$elem.on('click.jquiz', '.quiz--reset', $.proxy(function(event){
                me.reset();
            }, me));

            me.$elem.on('click.jquiz', '.quiz--final-reset', $.proxy(function(event){
                me.$step.removeClass(me.config.hide);
                me.$result.addClass(me.config.hide);
                me.reset();
            }, me));
        },

        nextStep: function() {
            var me = this;

            // set key
            if((me.currenIndex + 1) < me.max) {
                me.currenIndex++;
            }

            // set index

            // cal iniQuestions
        },

        prevStep: function() {
            var me = this;

        },

        getRelatedKeys: function() {
            var me = this;

            var r = [];
            var s = [];

            var f = me.finals;
            var k = me.prevKey;

            // get finals
            if(f.length === 0) f = me.config.answers;

            if(k === null) k = me.currentKey;

            for(var i = 0; i < f.length; i++) {
                for(var j = 0; j < me.stepValues[k].length; j++) {
                    if(me.inArray(me.stepValues[k][j], f[i].questions[k])) r.push(f[i]);
                }
            }

            me.finals = Array.from(new Set(r));

            if(me.config.jumpOver === true && me.finals.length === 1) {
                me.evaluate();
                return;
            }

            for(var i = 0; i < me.finals.length; i++) {
                s = s.concat(me.finals[i].questions[me.currentKey]);
            }

            me.finalKeys = Array.from(new Set(s));
        },

        evaluate: function() {
            var me = this;

            me.$resultContent.empty();

            var ids = me.finals.map(function(item){
                return item.id;
            });

            for(var i = 0; i < me.finals.length; i++) {
                me.$resultContent.append("<div class='result'>" +
                    "<div class='result-left'><img src='" + me.finals[i].image + "' alt='" + me.finals[i].name + "'></div>" +
                    "<div class='result-right'>" +
                    "<h3>" + me.finals[i].name + "</h3>" +
                    "<p>" + me.finals[i].desc + "</p></div></div>" +
                    "<div class='result-btn-wrapper'><a href='" +  me.finals[i].link +"' class='btn is--gold has--icon-right-arrow'>Im Shop</a>" +
                    "<a href='" +  me.finals[i].link +"' class='btn is--gold has--icon-right-arrow addquizcart' data-id='"+me.finals[i].id+"'>Zum Warenkorb hinzufügen</a></div>" +
                    "");
            }

            me.$step.addClass(me.config.hide);
            me.$result.removeClass(me.config.hide);
        },

        /**
         * Set Current, next and prev key and hide related buttons.
         */
        setKeys: function() {
            var me = this;

            // set startup keys
            me.currentKey = me.config.questions[me.currenIndex].key;

            /*if(me.currenIndex === 0 && me.finalKeys.length === 0) {*/
            if(me.currenIndex === 0) {
                me.$reset.addClass(me.config.hide);
            } else {
                me.$reset.removeClass(me.config.hide);
            }

            if((me.currenIndex + 1) < me.max) {
                me.nextKey = me.config.questions[me.currenIndex + 1].key;
                me.$next.removeClass(me.config.hide);
            } else {
                me.nextKey = null;
                //me.$next.addClass(me.config.hide);
            }

            if((me.currenIndex - 1) >= 0) {
                me.prevKey = me.config.questions[me.currenIndex - 1].key;
                me.$prev.removeClass(me.config.hide);
            } else {
                me.prevKey = null;
                me.$prev.addClass(me.config.hide);
            }
        },

        /**
         * Destroy plugin and unregister all events.
         */
        destroy: function() {
            var me = this;
            /*
                        // unbind all events
                        me.btn.off( ".jquiz");

                        // remove classes
                        me.$elem.removeClass(me.config.classname);
                        me.$elem.removeClass( me.config.containerclass);

                        // remove position class
                        me.$elem.removeClass('is--' + me.config.position);

                        // remove open status
                        me.$elem.removeClass(me.config.active);
                        me.btn.removeClass(me.config.active);

                        // remove overlay
                        if(me.config.overlay === true) {
                            me.overlay.remove();
                        }

                        // remove all data
                        me.$elem.removeData();*/
        },

        reset: function() {
            var me = this;

            me.currenIndex = 0;
            me.stepValues = {};
            me.finals = me.config.answers;
            me.finalKeys = [];

            me.currentKey = "";
            me.nextKey = "";
            me.prevKey = "";
            me.max = me.config.questions.length;

            me.setKeys();
            me.initQuestion();
        },

        inArray: function(target, array) {
            for(var i = 0; i < array.length; i++)
            {
                if(array[i] === target) return true;
            }

            return false;
        },

        uniqueArray: function (array) {
            return Array.from(new Set(array));
        },

        /**
         * Re initialise off canvas.
         */
        reInit: function() {
            var me = this;

            me.init();
        },
    };

    $.fn.jQuiz = function(options) {
        return this.each(function() {
            var plugin = new jQuiz(this, options).init();
            $(this).data('jQuiz', plugin);
        });
    };

})( jQuery, window , document );
