/* eslint-disable */
import jQuery from 'jquery';
/*
 * jSelect – Simple styleable slectfields
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.2 – Remove ul-list, append multi
 * version 0.1 – Initial version.
 */

// throw exeption if no jQuery
if (!jQuery) {
    throw new Error("jSelect equires jQuery");
}

;(function ($, window, document, undefined) {

    // our plugin constructor
    var jSelect = function (elem, options) {
        var me = this;

        me.elem = elem;
        me.$e = $(elem);
    };

    // the plugin prototype
    jSelect.prototype = {
        defaults: {
            sort: true,
            sortBy: 'text',
            sortDirection: 'DESC',
            hiddenfield: true,
            hiddenfieldname: 'test',
            text: 'Select …',
            emptyText: 'No itmes …',
            showActive: false,
            triggerList: true,
            hideAutoSelectFirst: true,
            triggerOnBuild: true,
            classes: {
                wrapper: 'jselect-wrapper',
                list: 'jselect-list',
                item: 'jselect-item',
                trigger: 'jselect-trigger',
                triggericon: 'jselect-triggericon',
                hidden: 'jselect-hidden',
                active: 'active',
                toggle: 'has-toggle',
                empty: 'is--empty',
                disabled: 'is--disabled'
            }
        },

        init: function () {
            var me = this;

            // merge config's
            me.config = $.extend({}, me.defaults, me.options, me.$e.get(0).dataset);

            // target
            me.$target = "";

            // build list
            _build(me);

            // register all events
            me.registerEvents();

            // trigger custom event
            me.$e.trigger("onInit", [me, me.value]);

            return me;
        },

        registerEvents: function () {
            var me = this;

            // toggle list
            me.$e.on('click.jselect touch.jselect', me.$list, $.proxy(function () {

                if (!me.disabled) {
                    me.toggleList();
                }
            }, me));

            // close if click outside
            $(document).on('click.jselect touch.jselect', $.proxy(function (e) {
                if (!me.disabled) {
                    if ($(e.target).parent().is(me.$e) === false) me.closeList();
                }
            }, me));

            // Change element
            $(me.$e).on('click.jselect touch.jselect', 'li', $.proxy(function (event) {

                if (!me.disabled) {
                    if ($(event.target).hasClass(me.config.classes.item)) {
                        me.$target = $(event.target);
                    } else {
                        me.$target = $(event.target).parent('.' + me.config.classes.item);
                    }

                    me.onChangeElement();
                }
            }, me));

        },

        destroy: function () {

        },

        /**
         * Unseelct evrything.
         */
        reset: function () {
            var me = this;

            $('li', me.$e).removeClass(me.config.classes.active);
            me.$valuefield = '';
            me.$trfield.html(me.config.text);

            me.$select.val("");

            if (me.multiple) {
                me.value = [];
            } else {
                me.value = "";
            }

            // close list
            me.toggleList();

            // trigger custom event
            me.$e.trigger("reset", [me]);
        },

        /**
         *
         */
        onChangeElement: function (trigger) {
            var me = this;

            if(typeof trigger === "undefined") trigger = true;

            if (me.multiple) {

                // multiple
                me.$target.toggleClass(me.config.classes.active);

                if (me.$target.hasClass(me.config.classes.active)) {
                    // add
                    me.value.push(me.getValue());
                    me.$select.get(0)[me.$target.index()].selected = true;
                } else {
                    // remove
                    me.value = jQuery.grep(me.value, function (value) {
                        return value !== me.$target.get(0).dataset.value;
                    });
                    me.$select.get(0)[me.$target.index()].selected = false;
                }

                if (me.config.triggerList && trigger) {
                    me.$select.trigger('change', [me, me.value]);
                }

            } else {

                if (me.$target.hasClass(me.config.classes.active)) {
                    me.$target = "";
                    me.value = "";
                    $('li', me.$e).removeClass(me.config.classes.active);

                    if (me.config.showActive) {
                        me.$trfield.html(me.config.text);
                    }

                } else {

                    $('li', me.$e).removeClass(me.config.classes.active);
                    me.$target.addClass(me.config.classes.active);
                    if (me.config.showActive) me.$trfield.html(me.$target.html());
                    me.$valuefield = me.getValue();
                    me.value = me.getValue();
                }

                if (me.config.triggerList) {
                    me.$select.get(0).value = me.getValue();

                    if (trigger) {
                        me.$select.trigger('change', [me, me.value]);
                    }
                }
            }

            // close list
            me.toggleList();

            // trigger custom event
            if (trigger) {
                me.$e.trigger("onChange", [me, me.$target, me.getValue()]);
            }
        },

        setValue: function (value) {
            var me = this;

            var $elem = $('li[data-value="' + value + '"]', me.$list);

            me.$target = $elem;

            me.onChangeElement(true);
            me.closeList();

        },

        removeValue: function (value) {
            var me = this;

            if (me.multiple) {

                var $elem = $('li[data-value="' + value + '"]', me.$list);

                $elem.removeClass(me.config.action);
                me.$target = $elem;
            }

            me.onChangeElement();
            me.closeList();

        },

        /**
         * Return current value
         * @returns {*}
         */
        getValue: function () {
            var me = this;

            if (typeof me.$target !== 'undefined' && me.$target !== "") {
                var val = me.$target.attr('data-value');

                if (typeof val === "undefined") {
                    return $('.' + me.config.classes.active, me.$e).text();
                }

                return val;
            } else {
                return "";
            }
        },

        /**
         * Toggle list
         */
        toggleList: function () {
            var me = this;

            me.$trfield.toggleClass(me.config.classes.active);
            me.$tricon.toggleClass(me.config.classes.active);
            me.$list.toggleClass(me.config.classes.active);

            // trigger custom event
            me.$e.trigger("onToggleList", [me]);
        },

        /**
         * Close list
         */
        closeList: function () {
            var me = this;

            me.$trfield.removeClass(me.config.classes.active);
            me.$tricon.removeClass(me.config.classes.active);
            me.$list.removeClass(me.config.classes.active);

            // trigger custom event
            me.$e.trigger("onCloseList", [me]);
        },

        /**
         * Type can be text, value or any data-attribut
         *
         * @param type
         */
        sortASC: function (type) {
            var me = this;
            _sort(me, type, 'ASC');
        },

        /**
         * Type can be text, value or any data-attribut
         *
         * @param type
         */
        sortDESC: function (type) {
            var me = this;
            _sort(me, type, 'DESC');
        },

        /**
         * Add new element
         *
         * @param text
         * @param value
         * @param data
         * @param position  if true, new item is prepend on first position
         */
        addItem: function (text, value, data, position, internal) {
            var me = this;

            if (typeof position === 'undefined') position = false;
            if (typeof internal === 'undefined') internal = false;

            var elem = $('<li />').addClass(me.config.classes.item).attr('data-value', value).append(text);

            if (!internal) {
                var opt = $('<option />').attr('value', value).text(text);

                if (position) {
                    me.$select.prepend(opt);
                } else {
                    me.$select.append(opt);
                }
            }

            if (typeof data === 'object') {
                $.each(data, function (i, e) {
                    elem.attr('data-' + i, e);
                });
            }

            if (position) {
                me.$list.prepend(elem);
            } else {
                me.$list.append(elem);
            }

            if (me.config.sort === true) _sort(me, me.config.sortBy, me.config.sortDirection);

            me.setEmpty();

            // trigger custom event
            me.$e.trigger("onAddItem", [me, me, elem]);
        },

        /**
         * Remove item
         *
         * @param type
         * @param value
         */
        removeItem: function (type, value) {
            var me = this;

            var list = '';
            var select = '';

            switch (type) {
                case 'text':
                    list = $('li:contains("' + value + '")', me.$list);
                    select = $('option:contains("' + value + '")', me.$select);
                    break;
                case 'value':
                    list = $('[data-value="' + value + '"]', me.$list).remove();
                    select = $('[value="' + value + '"]', me.$select).remove();
                    break;
                case 'index':
                    list = me.$list.children().eq(value).remove();
                    select = me.$select.children().eq(value);
                    break;
                default:
                    list = $('[data-' + type + '="' + value + '"]', me.$list);
                    select = $('[data-' + type + '="' + value + '"]', me.$select);
                    break;
            }

            if (list.hasClass(me.config.classes.active)) {
                me.$trfield.html(me.config.text);
                me.$valuefield = "";
            }

            me.setEmpty();

            // trigger custom event
            me.$e.trigger("onRemoveItem", [me]);
        },

        /**
         * Remove all items
         */
        removeAllItems: function () {
            var me = this;

            me.$list.empty();
            me.$select.empty();

            // add text
            me.$trfield.html(me.config.text);

            me.setEmpty();

            // trigger custom event
            me.$e.trigger("onRemoveItem", [me, me]);
        },

        /**
         * Set empty element
         */
        setEmpty: function () {
            var me = this;

            if (me.$list.children().length === 0) {
                me.$list.append(me.$empty);
                me.$e.addClass(me.config.classes.empty);
            } else if (me.$list.children().length > 1) {
                me.$e.removeClass(me.config.classes.empty);
                $('li.' + me.config.classes.empty, me.$list).remove();
            }
        },

        enableSelect: function () {
            var me = this;

            me.disabled = false;
            me.$select.prop('disabled', false);
            me.$list.removeClass(me.config.classes.disabled);
            me.$trfield.removeClass(me.config.classes.disabled);
            me.$tricon.removeClass(me.config.classes.disabled);

            me.$select.trigger('onChange');
        },

        disableSelect: function () {
            var me = this;

            if (me.disabled === false) {
                me.disabled = true;
                me.$select.prop('disabled', true);
                me.$list.addClass(me.config.classes.disabled);
                me.$trfield.addClass(me.config.classes.disabled);
                me.$tricon.addClass(me.config.classes.disabled);

                if (typeof me.$target != "undefined" && me.$target !== "") {
                    // me.onChangeElement();
                    // me.closeList();
                }
            }
        }
    };

    /**
     * Initial html build
     *
     * @param me
     * @private
     */
    var _build = function (me) {

        // add wrapper class
        me.$e.addClass(me.config.classes.wrapper);

        // triggerfield
        me.$trfield = $('<span />').addClass(me.config.classes.trigger).addClass(me.config.classes.toggle).html(me.config.text);
        me.$e.prepend(me.$trfield);

        //trigger icon
        me.$tricon = $('<i />').addClass(me.config.classes.triggericon).addClass(me.config.classes.toggle);
        me.$e.prepend(me.$tricon);

        // valuefield
        me.$valuefield = $('<input />').attr({
            'type': 'hidden',
            'name': me.config.hiddenfieldname,
            'value': ''
        }).addClass(me.config.hiddenfieldname);

        if (me.config.hiddenfield) me.$e.prepend(me.$valuefield);

        _buildListFromSelect(me);

        // empty element
        me.$empty = $('<li />').addClass(me.config.classes.empty + ' ' + me.config.classes.item).text(me.config.emptyText);
        me.setEmpty();

        // cleanup list
        _cleanUpList(me);
    };


    /**
     * Generate ul from select element
     *
     * @param me
     * @private
     */
    var _buildListFromSelect = function (me) {
        me.$list = $('<ul />');
        me.$select = me.$e.find('select');

        // multiple
        me.multiple = me.$select.get(0).multiple;

        // disabeld
        me.disabled = me.$select.get(0).disabled;

        if (me.disabled) {
            me.$list.addClass(me.config.classes.disabled);
            me.$list.removeClass(me.config.classes.active);
            me.$trfield.addClass(me.config.classes.disabled);
            me.$tricon.addClass(me.config.classes.disabled);
        }

        if (me.multiple) {
            me.value = [];
        } else {
            me.value = '';
        }

        var index = null;

        $.each(me.$select.children(), function (i, elem) {
            var option = $(elem);
            if (option.attr('selected') === 'selected') {
                index = i
            }
            ;

            var data = option.get(0).dataset;
            var text = option.html();
            var value = option.val();

            if (typeof data.text !== 'undefined') {
                text = data.text;
            }

            if (typeof data.value !== 'undefined') {
                value = data.value;
            }

            me.addItem(text, value, option.get(0).dataset, false, true);
        });

        me.$e.append(me.$list);
        me.$select.addClass(me.config.classes.hidden);


        if (index != null) {
            me.$target = $(me.$list.get(0).children[index]);

            me.onChangeElement(false);
            me.closeList();
        } else {

        }

    };

    /**
     * Manage missing data-value attributs
     *
     * @param me
     * @private
     */
    var _cleanUpList = function (me) {
        me.$list = me.$e.find('ul').addClass(me.config.classes.list);
        me.$list.children().addClass(me.config.classes.item);

        $.each(me.$list.children(), function (i, elem) {
            if (typeof $(elem).data('value') === 'undefined') {
                if (typeof $(elem).attr('value') === 'undefined') {
                    $(me.$list.children().get(i)).attr('data-value', $(elem).text());
                } else {
                    $(me.$list.children().get(i)).attr('data-value', $(elem).attr('value'));
                    $(me.$list.children().get(i)).removeAttr('value');
                }
            }
        });

        if (me.config.sort === true) _sort(me, me.config.sortBy, me.config.sortDirection);

        if (me.disabled) me.closeList();
    };

    /**
     * Sort list
     *
     * @param me
     * @param type
     * @param direction
     * @private
     */
    var _sort = function (me, type, direction) {

        if (type === 'text') {

            // selecet list
            me.$list.children().sort(function (a, b) {

                if (direction === 'ASC') {
                    return ($(b).text() > $(a).text()) ? 1 : -1;
                } else {
                    return ($(a).text() > $(b).text()) ? 1 : -1;
                }
            }).appendTo(me.$list);

            // select original
            me.$select.children().sort(function (a, b) {

                if (direction === 'ASC') {
                    return ($(b).text() > $(a).text()) ? 1 : -1;
                } else {
                    return ($(a).text() > $(b).text()) ? 1 : -1;
                }
            }).appendTo(me.$select);

        } else {

            // select list
            me.$list.children().sort(function (a, b) {

                if (direction === 'ASC') {
                    return ($(b).data(type) > $(a).data(type)) ? 1 : -1;
                } else {
                    return ($(a).data(type) > $(b).data(type)) ? 1 : -1;
                }
            }).appendTo(me.$list);

            // select original
            me.$select.children().sort(function (a, b) {

                if (direction === 'ASC') {
                    return ($(b).data(type) > $(a).data(type)) ? 1 : -1;
                } else {
                    return ($(a).data(type) > $(b).data(type)) ? 1 : -1;
                }
            }).appendTo(me.$select);
        }

        me.$e.trigger('onSort', [me, type, direction]);
    };

    $.fn.jSelect = function (options) {
        return this.each(function () {
            var plugin = new jSelect(this, options).init();
            $(this).data('jSelect', plugin);
        });
    };

    // self ini
    $(document).ready(function () {
        $("[data-jselect='true']").jSelect();
    });

})(jQuery, window, document);
