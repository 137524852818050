/* eslint-disable */
import jQuery from 'jquery';


/*
 * jCommerceCart – Off Canvas plugin
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.1 – Initial version.
 */

// throw exeption if no jQuery
if (!jQuery) {
    throw new Error("jCommerceCart requires jQuery");
}

;(function ($, window, document, undefined) {

    // our plugin constructor
    var jCommerceCart = function (elem) {
        var me = this;

        me.$elem = $(elem);
        me.metadata = {};
    };

    // the plugin prototype
    jCommerceCart.prototype = {
        defaults: {
            active: 'is--active',
            canvas: "#cart",
            cartcontent: '.cart--content',
            optionsSelector: '.product-variant input, .product-coffee-variant input',
            add2cartSelector: '.btn-variant-addtocart',
            add2cartbtn: '.btn-addtocart',
            warning: 'massage--warning',
            success: 'massage--success',
            error: 'massage--error',
            messages: 'massage',
            loading: 'is--loading',
            controller: {
                cartcontent: '/ajaxcart',
                cart: '/shop/cart'
            }
        },

        init: function () {
            var me = this;

            // Introduce defaults that can be extended either globally or using an object literal.
            me.config = $.extend({}, me.defaults, me.options, me.$elem.get(0).dataset);

            // off canvas
            me.canvas = $(me.config.canvas, me.$elem);
            me.$cartcontent = $(me.config.cartcontent, me.canvas);

            // btn
            me.btn = $(me.config.add2cartbtn, me.$elem);

            // get cart messages
            me.$messages = $('.' + me.config.messages, me.canvas);
            me.$success = $('.' + me.config.success, me.canvas);
            me.$error = $('.' + me.config.error, me.canvas);
            me.$warning = $('.' + me.config.warning, me.canvas);

            // cart amount
            me.$cartAmount = $('.cart--amount', me.$elem);
            me.$cartQty = $('.cart--qty', me.$elem);

            // context key
            me.context = $('head').attr('data-context');

            me.add = false;


            // variant product
            // options
            me.radio = $(me.config.optionsSelector, me.$elem);
            me.variantWrapper = $('.buy-box-form');

            if (me.radio && me.radio.length > 0 && me.variantWrapper) {
                me.variantParent = me.variantWrapper.parents('.product');
                me.registerVariantEvents();
            }

            // register all events
            me.registerEvents();

            return me;
        },

        registerVariantEvents() {
            var me = this;

            $(document).on('click', this.config.add2cartSelector, $.proxy(function (event, a, b, c) {
                event.preventDefault();

                var id = $('.product-variant input:checked, .product-coffee-variant input:checked').val();

                var qty = $('#jQty [data-jselect="true"]').data('jSelect').getValue();

                if (qty === 0 || qty === '') qty = 1;

                me.addProduct(id, qty, '');
            }, me));

            $(document).on('change', this.config.optionsSelector, $.proxy(function (event, a, b, c) {
                event.preventDefault();
                me.switchVariant(event);
            }, me));
        },


        /**
         * Switch variant and load new buy box
         */
        switchVariant: function (event) {
            var me = this;

            var id = event.target.value;

            var data = $.extend({
                action: "roestereivierajax",
                run: "switch_variant",
                context: me.context,
                productId: id,
                resourceId: $('[itemprop="productID"]').attr('content')
            }, event.target.dataset);

            me.variantParent.addClass('is--loading');

            $.ajax({
                    url: '/assets/components/roestereivier/connector.php',
                    method: "POST",
                    dataType: "json",
                    data: data,
                    success: function (result) {

                        if (result.success) {
                            // change image
                            var image = $('.mainSlider [data-id="' + id + '"]');
                            if (image.length === 1) {
                                var index = parseInt(image.get(0).dataset.index, 10);
                                window.swiper.slideTo(index, 0);
                            }
                            me.variantWrapper.empty();
                            me.variantWrapper.append($(result.results.content));
                            $('#jQty [data-jselect="true"]', me.variantWrapper).jSelect({
                                triggerOnBuild: false
                            });

                            history.replaceState({}, "", window.location.pathname + window.location.hash + '?productId=' + id);
                        } else {
                            alert('Das Produkt konnte nicht geladen werden.')
                        }
                        me.variantParent.removeClass('is--loading');

                    }
                }
            )
            ;

            me.add = false;
        },

        /**
         * Register all plugin events.
         */
        registerEvents: function () {
            var me = this;

            $(document).on('click', '.btn-addtocart', $.proxy(function (event, a, b, c) {
                event.preventDefault();

                var form = $(event.target).parents('form');
                var id = $('#shop--product', form).attr('data-id');

                if ($('#shop--variant select', form).length === 1) {
                    id = $('#shop--variant', form).parent().data('jSelect').value;
                }

                if ($('#jVariant .jselect-wrapper', form).length === 1) {
                    id = $('#jVariant .jselect-wrapper', form).data('jSelect').value;
                }

                var qty = $('#add-quantity', form).parent().data('jSelect').value;

                var comment = '';
                if ($('#shop--variant-detail', form).length === 1) {
                    comment = $('#shop--variant-detail', form).parent().data('jSelect').value;
                }

                // new way
                comment = $('[name="comment"]', form).val();
                // qty = $('[name="qty"]', form).val();
                qty = $('#jQty [data-jselect="true"]').data('jSelect').getValue();

                if (qty === 0 || qty === '') qty = 1;

                console.log(id);

                me.addProduct(id, qty, comment);

            }, me));

            // add tip
            $(document).on('click', '.btn-addtocart-tip', $.proxy(function (event) {
                event.preventDefault();

                var v = $(event.target).attr('data-value');
                var id = $(event.target).attr('data-id');

                switch (v) {
                    case "free":
                        v = parseInt($('#support-freevalue').val());
                        me.addProduct(id, 1, "", v * 100);
                        break;
                    case "1":
                        me.addProduct(id, 1, "", v * 100);
                        break;
                    case "2":
                        me.addProduct(id, 1, "", v * 100);
                        break;
                    case "10":
                        // get order total
                        $.ajax({
                            url: '/assets/components/roestereivier/connector.php',
                            method: "POST",
                            dataType: "json",
                            data: {
                                action: "roestereivierajax",
                                run: "total",
                                context: me.context
                            },
                            success: function (result) {
                                if (result.success && result.results.json.subtotal * 0.1 > 0) {
                                    me.addProduct(id, 1, "", result.results.json.subtotal * 0.1);
                                }
                            }
                        });

                        break;
                    default:
                        return;
                }

                // me.addProduct(a, qty, comment);

            }, me));

            me.$cartcontent.on('click', '.cart--delete-btn', $.proxy(function (event) {
                event.preventDefault();

                me.deleteProduct(event.target.closest('.cart--item').dataset.id);
            }, me));

            me.canvas.on('onBeforeOpenCanvas.jCommerceCart', $.proxy(function (event) {
                event.preventDefault();

                if (me.add === false) {
                    me.isLoading(true);
                    me.getCartContent();
                }
            }, me));

            me.$cartcontent.on('onChange', '[data-comment]', $.proxy(function (a, b, c, d) {
                var elem = a.target.closest('.cart--item').dataset.id;
                me.changeComment(elem, d);
            }, me));

            me.$cartcontent.on('onChange', '[data-qty-select]', $.proxy(function (a, b, c, d) {

                if (d >= 0 && d <= 20) {
                    me.updateQty(a.target.closest('.cart--item').dataset.id, d);
                }
            }, me));

            me.$cartcontent.on('click', '.offcanvas-cross-selling-add', $.proxy(function (a) {
                me.addProduct(a.target.dataset.id, 1, a.target.dataset.comment);
            }, me));

        }
        ,

        reRegisterPlugins: function () {
            $('#support-offcanvas-cta').removeClass('is--hidden');

            $('.cart--content [data-jselect="true"]').jSelect({
                triggerOnBuild: false
            });

            $(".cart--content [data-qty='true']").jQuantity();

            $('.cart--content .offcanvas-cross-selling-slider').slick({
                adaptiveHeight: true
            });
        }
        ,

        /**
         * Load cart content via ajax.
         *
         */
        getCartContent: function () {
            var me = this;

            me.cleanUpCanvas();
            me.isLoading(true);

            $.ajax({
                url: '/assets/components/roestereivier/connector.php',
                method: "POST",
                dataType: "json",
                data: {
                    action: "roestereivierajax",
                    run: "cart",
                    context: me.context
                }
            }).done(function (result) {
                me.isLoading(false);
                if (result.results.cart.length === 1 || result.results.cart === "") {
                    me.$warning.addClass(me.config.active);
                } else {
                    me.$cartcontent.append(result.results.cart);
                    me.reRegisterPlugins();
                }
            }).fail(function () {
                me.isLoading(false);
                me.$error.addClass(me.config.active);
            });
        }
        ,

        /**
         * Re initialise off canvas.
         */
        reInit: function () {
            var me = this;

            me.init();
        }
        ,

        openCanvas: function () {
            me.canvas.data('jCanvas').openCanvas();
        }
        ,

        /**
         * Add product to cart.
         */
        addProduct: function (id, qty, comment, price) {
            var me = this;

            if (typeof qty === "undefined") qty = 1;
            if (typeof comment === "undefined") comment = "";

            me.add = true;

            me.cleanUpCanvas();
            me.isLoading(true);
            me.canvas.data('jCanvas').openCanvas();

            var data = {
                action: "roestereivierajax",
                run: "add_and_delete",
                add_to_cart: 1,
                product: id,
                quantity: qty,
                context: me.context
            };

            if (comment.length > 0) {
                data.comment = comment;
            }

            if (price > 0) {
                data.price = parseInt(price);
            }

            $.ajax({
                url: '/assets/components/roestereivier/connector.php',
                method: "POST",
                dataType: "json",
                data: data,
                success: function (result) {

                    if (result.success) {
                        if (result.order.items.length > 0) {
                            me.getCartContent();
                            me.$success.text(result.message).addClass(me.config.active);
                            me.$cartAmount.text(result.order.subtotal_formatted);
                            me.$cartQty.text(result.order.total_quantity);
                            if (result.order.total_quantity > 0) {
                                me.$cartQty.addClass('is--active')
                                me.$cartQty.parents('li').addClass('is--active')
                            } else {
                                me.$cartQty.removeClass('is--active')
                                me.$cartQty.parents('li').removeClass('is--active')
                            }
                        } else {
                            me.$error.text("Error while add product to cart.").addClass(me.config.active);
                        }
                    } else {
                        if (result.message && result.message.length) {
                            me.$error.text(result.message).addClass(me.config.active);
                            me.canvas.data('jCanvas').openCanvas();
                        } else {
                            me.$error.text("Could not add the product to the cart.").addClass(me.config.active);
                        }
                    }
                }
            });

            me.add = false;
        }
        ,

        updateQty: function (id, qty) {
            var me = this;
            me.add = true;

            if (typeof qty == "undefined") qty = 0;

            me.cleanUpCanvas();
            me.isLoading(true);
            me.canvas.data('jCanvas').openCanvas();

            var data = {
                action: "roestereivierajax",
                run: "update_qty",
                product: id,
                quantity: qty,
                context: me.context
            };

            $.ajax({
                url: '/assets/components/roestereivier/connector.php',
                method: "POST",
                dataType: "json",
                data: data,
                success: function (result) {
                    if (result.results.cart.length === 1 || result.results.cart === "") {
                        me.$warning.addClass(me.config.active);
                    } else {
                        me.$cartcontent.append(result.results.cart);
                        me.reRegisterPlugins();
                    }
                    me.isLoading(false);
                }
            });

            me.add = false;
        }
        ,

        /**
         * Delete product from cart.
         */
        deleteProduct: function (id) {
            var me = this;

            me.add = true;

            me.cleanUpCanvas();
            me.isLoading(true);
            me.canvas.data('jCanvas').openCanvas();

            $.ajax({
                url: '/assets/components/roestereivier/connector.php',
                method: "POST",
                dataType: "json",
                data: {
                    action: "roestereivierajax",
                    run: "add_and_delete",
                    remove_item: id,
                    quantity: 1,
                    context: me.context
                },
                success: function (result) {

                    if (result.success) {

                        me.getCartContent();
                        me.$success.text(result.message).addClass(me.config.active);
                        me.$cartAmount.text(result.order.subtotal_formatted);
                        me.$cartQty.text(result.order.total_quantity);

                        if (result.order.total_quantity > 0) {
                            me.$cartQty.addClass('is--active')
                            me.$cartQty.parents('li').addClass('is--active')
                        } else {
                            me.$cartQty.removeClass('is--active')
                            me.$cartQty.parents('li').removeClass('is--active')
                        }
                    } else {
                        if (result.message && result.message.length) {
                            me.$error.text(result.message).addClass(me.config.active);
                        } else {
                            me.$error.text("Could not add the product to the cart.").addClass(me.config.active);
                        }
                    }
                }
            });

            me.add = false;
        }
        ,

        /**
         * Remove all items from canvas
         */
        cleanUpCanvas: function () {
            var me = this;

            me.$messages.removeClass(me.config.active);
            me.$cartcontent.empty();
            $('#support-offcanvas-cta').addClass('is--hidden');
        }
        ,

        /**
         * Add / remove loading gif class
         *
         * @param status
         */
        isLoading: function (status) {
            var me = this;

            if (status) {
                me.canvas.addClass(me.config.loading);
            } else {
                me.canvas.removeClass(me.config.loading);
            }
        }
        ,

        /**
         * Destroy plugin and unregister all events.
         */
        destroy: function () {
            var me = this;

            // unbind all events
            me.btn.off(".jCommerceCart");
            /*
                        // remove classes
                        me.$elem.removeClass(me.config.classname);
                        me.$elem.removeClass( me.config.containerclass);

                        // remove position class
                        me.$elem.removeClass('is--' + me.config.position);

                        // remove open status
                        me.$elem.removeClass(me.config.active);
                        me.btn.removeClass(me.config.active);

                        // remove overlay
                        me.overlay.remove();

                        // remove all data
                        me.$elem.removeData();
                        */
        }
        ,

        /**
         * Cahnge commment of cart item
         */
        changeComment: function (id, comment) {
            var me = this;


            me.cleanUpCanvas();
            me.isLoading(true);

            var data = {
                action: "roestereivierajax",
                run: "update_comment",
                itemId: id,
                comment: comment,
                context: me.context
            };

            $.ajax({
                url: '/assets/components/roestereivier/connector.php',
                method: "POST",
                dataType: "json",
                data: data,
                success: function (result) {
                    if (result.results.cart.length === 1 || result.results.cart === "") {
                        me.$warning.addClass(me.config.active);
                    } else {
                        me.$cartcontent.append(result.results.cart);
                        me.reRegisterPlugins();
                    }
                    me.isLoading(false);
                }
            });

            me.add = false;
        }
        ,
    };

    $.fn.jCommerceCart = function () {
        return this.each(function () {
            var plugin = new jCommerceCart(this).init();
            $(this).data('jCommerceCart', plugin);
        });
    };

    /**
     * Self ini plugin.
     */
    $(document).ready(function () {
        $("[data-commerceajaxcart='true']").jCommerceCart();
    });

})
(jQuery, window, document);
