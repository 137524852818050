/* eslint-disable */
import jQuery from 'jquery';
/*
 * jCollapse – Collapse / toogle elements
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.1 – Initial version.
 */

// throw exeption if no jQuery
if (!jQuery) { throw new Error("jCollapse requires jQuery"); }

;(function( $, window, document, undefined ){

    // our plugin constructor
    var jCollapse = function( elem ){
        var me = this;

        me.$elem = $(elem);

    };

    // the plugin prototype
    jCollapse.prototype = {
        defaults: {
            active: 'is--active',
            hidden: 'is--hidden',
            collapseTarget: 'next',
            wrapperClass: 'jcollapse--wrapper',
            textOpen: ' Mehr ›',
            textClose: 'x schließen',
            openClass: 'jcollapse--open-btn',
            closeClass: 'jcollapse--close-btn',
        },

        init: function () {
            var me = this;

            me.config = $.extend({}, me.defaults, me.options, me.$elem.get(0).dataset);

            // Introduce defaults that can be extended either globally or using an object literal.            // get collapse wrapper
            if(me.config.collapseTarget === "next") {
                me.$wrapper = me.$elem.next();
            } else {
                me.$wrapper = $(me.config.collapseTarget);
            }

            me.$wrapper.addClass(me.config.wrapperClass);

            // open and close links
            me.$open = $('<span />').addClass(me.config.openClass).text(me.config.textOpen).appendTo(me.$elem);
            me.$close = $('<span />').addClass(me.config.openClass).text(me.config.textClose).appendTo(me.$wrapper);

            me.$wrapper.hide(0);

            // Events
            me.registerEvents();

            return me;
        },
        collapseIn: function() {
            var me =this;

            // append link
            // me.$wrapper.addClass(me.config.active).height(me.wrapperheight);
            me.$open.addClass(me.config.hidden);

            me.$wrapper.slideToggle();

        },

        collapseOut: function() {
            var me =this;

            // append link
            // me.$wrapper.removeClass(me.config.active).height(0);
            me.$open.removeClass(me.config.hidden);

            me.$wrapper.slideToggle();

        },

        /**
         * Re initialise off canvas.
         */
        reInit: function() {
            var me = this;

            me.init();
        },

        /**
         * Register all plugin events.
         */
        registerEvents: function() {
            var me = this;

            me.$open.on('click.jCollapse', $.proxy(function(event) {
                me.collapseIn();

            }, me));

            me.$close.on('click.jCollapse', $.proxy(function(event) {
                me.collapseOut();
            }, me));

            var resizeTimer;

            /*
            $(window).on('resize.jCollapse', function(e) {
                clearTimeout(resizeTimer);
                resizeTimer = setTimeout(function() {
                    me.setHeight();
                }, 250);
            });
            */
        },

        /**
         * Destroy plugin and unregister all events.
         */
        destroy: function() {
            var me = this;

            // unbind all events
            me.$elem.off( ".jCollapse");
            $(window).off( ".jCollapse");

            //remove
            me.$open.remove();
            me.$close.remove();

            me.$wrapper.removeClass(me.config.wrapperClass).height('auto');

            // remove all data
            me.$elem.removeData();
        },


    };

    $.fn.jCollapse = function() {
        return this.each(function() {
            var plugin = new jCollapse(this).init();
            $(this).data('jCollapse', plugin);
        });
    };

    /**
     * Self ini plugin.
     */
    $(document).ready(function() {
        $("[data-collapse='true']").jCollapse();
    });

})( jQuery, window , document );
