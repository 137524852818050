/* eslint-disable */
import jQuery from 'jquery';
/*
 * jProduct
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.1 – Initial version.
 */

// throw exeption if no jQuery
if (!jQuery) {
    throw new Error("jProduct requires jQuery");
}

;(function ($, window, document, undefined) {

    // our plugin constructor
    var jProduct = function (elem) {
        var me = this;

        me.$elem = $(elem);

    };

    // the plugin prototype
    jProduct.prototype = {
        defaults: {
            classes: {
                variant: '#jVariant',
                qty: '#jQty',
                add2cart: '#jAdd2cart',
                upto: '#jPriceUpto',
                price: '#jPrice',
            }
        },


        init: function () {
            var me = this;

            // Introduce defaults that can be extended either globally or using an object literal.
            me.config = $.extend({}, me.defaults, me.options, me.$elem.get(0).dataset);

            me.$variant = $(me.config.classes.variant, me.$elem);
            me.$qty = $(me.config.classes.qty, me.$elem);
            me.$add2cart = $(me.config.classes.add2cart, me.$elem);
            me.$upto = $(me.config.classes.upto, me.$elem);
            me.$price = $(me.config.classes.price, me.$elem);

            me.$formComment = $('[name="comment"]', me.$elem);
            me.$formQty = $('[name="qty"]', me.$elem);

            me.$variantSelect = $('[data-jselect="true"]', me.$variant);
            me.$qtySelect = $('[data-jselect="true"]', me.$qty);

            me.registerEvents();

            return me;
        },


        /**
         * Register all plugin events.
         */
        registerEvents: function() {
            var me = this;

            // toggle variants
            me.$variantSelect.on('change', $.proxy(function(event, inst, value){

                //toggle price

                if(value === '') {
                    // reset

                    me.$qtySelect.data('jSelect').disableSelect();
                    me.$add2cart.prop('disabled', true);
                } else {
                    // change price
                    me.setPrice(me.$variantSelect.find('[value="'+value+'"]').attr('data-price-formatted'));

                    me.$qtySelect.data('jSelect').enableSelect();
                    me.$add2cart.prop('disabled', false);
                }

            }, me));

            // select
            me.$qtySelect.on('change', $.proxy(function(event, inst, value){
                me.$formQty.val(value);
            }, me));
        },

        setPrice: function(price) {
            var me = this;

            if(price === 0 || price === '') {
                me.$upto.removeClass('is--hidden');
                me.$price.html(me.getLowestPrice());
            } else {
                me.$upto.addClass('is--hidden');
                me.$price.html(price);
            }
        },

        /**
         * Get loewest price from variant select list.
         *
         * @returns {*}
         */
        getLowestPrice: function() {
            var me = this;

            var p = 0;
            me.$variantSelect.find('option').each(function(index, elem) {
                if($(elem).attr('data-price') > p) {
                    p = $(elem).attr('data-price');
                }
            });

            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(p / 100).replace(' ', '&thinsp;');
        }
    };

    $.fn.jProduct = function () {
        return this.each(function () {
            var plugin = new jProduct(this).init();
            $(this).data('jProduct', plugin);
        });
    };

    /**
     * Self ini plugin.
     */
    $(document).ready(function () {
        $("[data-cproduct='true']").jProduct();
    });

})(jQuery, window, document);
