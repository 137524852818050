/* eslint-disable */
import $ from 'jquery';


import './es6'
// import './vendors/jquery.prime.js';
import './vendors/jquery.jQuantity.js';
import './vendors/jquery.jCanvas.min.js';
import './vendors/jquery.jStyleLoader.min.js';
import './vendors/slick.min.js';
import './vendors/jquery.jFullPage.js';
import './vendors/jquery.jscrollsmooth.min.js';
import './vendors/jquery.jselect.min.js';
import './vendors/jquery.jfilter.min.js';
import './vendors/jquery.jCollapse.min.js';
import './vendors/jquery.ajax.cart.js';
import './vendors/jQuiz.js';
import './vendors/jquery.formhandling.js';
import './vendors/jquery.jModal.js';
import './vendors/jquery.jCoffeProduct.js';
import './vendors/jquery.jRareProduct.js';
import './vendors/jquery.jProduct.js';
import './vendors/jquery.validate.min.js';
import './vendors/jquery.zipcode.js';


$(document).ready(function () {

    $('html').removeClass('no-js').addClass('js');

    /* ###  Logo ### */
    if (!$('body').hasClass('tpl--home')) {
        $(window).on('scroll.logo', function () {
            if ($(this).scrollTop() >= 200) {
                $('header .brandname').addClass('is--active');
            } else {
                $('header .brandname').removeClass('is--active');
            }
        });
    } else {
        var fLogo = $('#footer').offset();
        $(window).on('scroll', function () {
            // console.log($(window).scrollTop(), $(window).height(), $(document).innerHeight() - $(window).height() - $(window).height());

            if ($(this).scrollTop() >= $(document).innerHeight() - ($(window).height() * 1.7)) {
                $('.page-head .logo').addClass('has--fadeout');
            } else {
                $('.page-head .logo').removeClass('has--fadeout');
            }
        });
    }

    /* #### FULLAPGE STARTSEITE #### */
    $(window).resize(function () {
        var fullPage = $('[data-fullpage]').data('jFullPage');

        if ($(window).width() < 768) {
            if (typeof fullPage !== 'undefined') fullPage.destroy();
        } else {
            if (typeof fullPage === 'undefined') $('[data-fullpage]').jFullPage();
        }
    }).resize();

    /* ### Shop ### */
    function setFilter() {
        $.each($('.shop--listing-section'), function (index, elem) {
            if ($('.filter--nav-list li').length === 0) {
                $(elem).removeClass('is--hidden');
            } else {
                if ($('.is--active', elem).length === 0) {
                    $(elem).addClass('is--hidden');
                } else {
                    $(elem).removeClass('is--hidden');
                }
            }
        });
    }

    setFilter();

    var shopVariantDetail = $('[name="shop--variant-detail"]');

    var shopVariant = $('[name="shop--variant"]');

    var shopQty = $('[name="quantity"]');

    var btnAddtocart = $('[name="btn-addtocart"]');

    var filterCatgorie = $('[name="filter-categorie"]');

    var filter = $('[data-filter="true"]');

    filter.on('change', function (a) {
        setFilter();
    });

    filter.on('reset', function (a) {
        $('.shop--listing-section').removeClass('is--hidden');
    });
    /*
        if(shopVariantDetail.length > 0) {
            shopVariantDetail.on('change', function(a,b,c){

                if(shopVariant.length > 0 && c !== "" && shopVariant.parent().data('jSelect').getValue() !== "") {
                    shopQty.parent().data('jSelect').enableSelect();
                    btnAddtocart.prop('disabled', false);
                } else if(shopVariant.length === 0 && c !== "" && shopVariantDetail.parent().data('jSelect').getValue() !== "") {
                    shopQty.parent().data('jSelect').enableSelect();
                    btnAddtocart.prop('disabled', false);
                } else {
                    shopQty.parent().data('jSelect').disableSelect();
                    btnAddtocart.prop('disabled', true);
                }
            });
        } else {
            // shopQty.parent().data('jSelect').enableSelect();
        }

        shopVariant.on('change',function(a,b,c){

            $('.shop--price').html($('option:selected',shopVariant).attr('data-price-formatted'));
            $('.shop--price-upto').hide();

            if(c === "" && shopVariantDetail.length > 0 && shopVariantDetail.parent().data('jSelect').value === "") {
                $('.shop--price').text($('.shop--price').attr('data-from'));
                $('.shop--price-upto').show();
            }

            if(c !== "" && shopVariantDetail.length > 0 && shopVariantDetail.parent().data('jSelect').value !== "") {
                shopQty.parent().data('jSelect').enableSelect();
                btnAddtocart.prop('disabled', false);
            } else {
                shopQty.parent().data('jSelect').disableSelect();
                btnAddtocart.prop('disabled', true);
            }

            if(shopVariantDetail.length === 0 ) {
                shopQty.parent().data('jSelect').enableSelect();
                btnAddtocart.prop('disabled', false);
            }
        });
    */
    /* #### SLICK SLIDER #### */
    // Slider on before slide change
    $('.slide--wrapper').on('init', function (slick) {

        // remove dots if only one image
        if ($('.slider--item', slick.target).length === 1) {
            $('.slick-dots', slick.target).hide();
        }

        // ini style loader
        $.each($('.slick-active', slick.target).find('[data-css-background-image]'), function (index, elem) {
            $(elem).jStyleLoader();
        });
    });

    // Slider on after change
    $('.slide--wrapper').on('afterChange', function (slick, currentSlide) {

        // ini style loader
        $.each($('.slick-active', slick.target).find('[data-css-background-image]'), function (index, elem) {
            $(elem).jStyleLoader();
        });
    });

    // pdp cross selling slider
    $('[data-pdp-cross-selling="true"]').slick({
        infinite: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    });

    // cart cross selling slider
    $('[data-cart-cross-selling="true"]').slick({
        infinite: true,
        dots: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    });

    // Slick slider ini
    $('[data-slick^="{"]').slick({});
    $('[data-slick="multiple"]').slick({
        infinite: true,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }]
    });

    //$('#registerForm').validator();

    /* ### Header Roggle ### */
    if ($('.content--header h2 ~ div').length > 0) {
        $('.content--header h2').jCollapse();
    }


    /* #### Google Map #### */
    function loadMap() {
        var mapOptions = {
            zoom: 16,

            center: new google.maps.LatLng(51.2253661, 6.7720662),

            styles: [
                {
                    "featureType": "all",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.text.fill",
                    "stylers": [
                        {
                            "color": "#000000"
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.text.stroke",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "all",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "administrative.locality",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "landscape",
                    "elementType": "all",
                    "stylers": [
                        {
                            "color": "#ffffff"
                        }
                    ]
                },
                {
                    "featureType": "poi",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "weight": 1
                        },
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "color": "#000000"
                        },
                        {
                            "weight": 0.8
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road",
                    "elementType": "labels.icon",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "saturation": "-100"
                        },
                        {
                            "lightness": "0"
                        },
                        {
                            "gamma": "0.00"
                        },
                        {
                            "weight": "1"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.arterial",
                    "elementType": "labels.text",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "road.local",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "transit",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "transit.station",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "on"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "all",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.fill",
                    "stylers": [
                        {
                            "visibility": "on"
                        },
                        {
                            "saturation": "-100"
                        },
                        {
                            "gamma": "1.00"
                        },
                        {
                            "lightness": "54"
                        },
                        {
                            "color": "#000000"
                        }
                    ]
                },
                {
                    "featureType": "water",
                    "elementType": "geometry.stroke",
                    "stylers": [
                        {
                            "visibility": "off"
                        }
                    ]
                }
            ]
        };
        var mapElement = document.getElementById('map');
        var map = new google.maps.Map(mapElement, mapOptions);

        var marker1 = new google.maps.Marker({
            position: new google.maps.LatLng(51.2256142, 6.7714386),
            map: map,
            title: 'Rösterei VIER – Altstadt',
            icon: {
                url: 'assets/components/roestereivier/images/icons/map-marker.svg',
                // This marker is 20 pixels wide by 32 pixels high.
                size: new google.maps.Size(33, 54),
                // The origin for this image is (0, 0).
                origin: new google.maps.Point(0, 0),

                // The anchor for this image is the base of the flagpole at (0, 32).
                anchor: new google.maps.Point(16, 54),
                scaledSize: new google.maps.Size(33, 54)
            }
        });

        var marker2 = new google.maps.Marker({
            position: new google.maps.LatLng(51.2245323, 6.7736305),
            map: map,
            title: 'Rösterei VIER – Carlstadt',
            icon: {
                url: 'assets/components/roestereivier/images/icons/map-marker.svg',
                // This marker is 20 pixels wide by 32 pixels high.
                size: new google.maps.Size(33, 54),
                // The origin for this image is (0, 0).
                origin: new google.maps.Point(0, 0),
                // The anchor for this image is the base of the flagpole at (0, 32).
                anchor: new google.maps.Point(16, 54),
                scaledSize: new google.maps.Size(33, 54)
            }
        });

        var marker3 = new google.maps.Marker({
            position: new google.maps.LatLng(51.2239391, 6.7848273),
            map: map,
            title: 'Rösterei VIER – LITTLE TOKYO',
            icon: {
                url: 'assets/components/roestereivier/images/icons/map-marker.svg',
                // This marker is 20 pixels wide by 32 pixels high.
                size: new google.maps.Size(33, 54),
                // The origin for this image is (0, 0).
                origin: new google.maps.Point(0, 0),
                // The anchor for this image is the base of the flagpole at (0, 32).
                anchor: new google.maps.Point(16, 54),
                scaledSize: new google.maps.Size(33, 54)
            }
        });

        marker1.setMap(map);
        marker2.setMap(map);
        marker3.setMap(map);

        var contentString1 = '<div style="color: #000;">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h1 id="firstHeading" class="firstHeading" style="font-size: 1.2em">Rösterei VIER – Altstadt</h1>' +
            '<div id="bodyContent">' +
            '<address style="font-style: normal;">Marktplatz 12<br>40213 Düsseldorf<br><br>' +
            '0211 - 171 460 31</address>' +
            '</div>' +
            '</div>';

        var infowindow1 = new google.maps.InfoWindow({
            content: contentString1,
            maxWidth: 300
        });

        var contentString2 = '<div style="color: #000;">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h1 id="firstHeading" class="firstHeading" style="font-size: 1.2em">Rösterei VIER – Carlstadt</h1>' +
            '<div id="bodyContent">' +
            '<address style="font-style: normal;">Wallstraße 10<br>40213 Düsseldorf<br><br>0211 - 869 388 3</address>' +
            '</div>' +
            '</div>';

        var infowindow2 = new google.maps.InfoWindow({
            content: contentString2,
            maxWidth: 300
        });

        var contentString3 = '<div style="color: #000;">' +
            '<div id="siteNotice">' +
            '</div>' +
            '<h1 id="firstHeading" class="firstHeading" style="font-size: 1.2em">Rösterei VIER – LITTLE TOKYO</h1>' +
            '<div id="bodyContent">' +
            '<address style="font-style: normal;">Immermanstraße 23<br>40210 Düsseldorf @ Me and all Hotels<br><br>0211 - 869 388 3</address>' +
            '</div>' +
            '</div>';

        var infowindow3 = new google.maps.InfoWindow({
            content: contentString3,
            maxWidth: 300
        });


        marker1.addListener('click', function () {
            infowindow2.close();
            infowindow3.close();
            infowindow1.open(map, marker1);
        });
        marker2.addListener('click', function () {
            infowindow1.close();
            infowindow3.close();
            infowindow2.open(map, marker2);
        });
        marker3.addListener('click', function () {
            infowindow1.close();
            infowindow2.close();
            infowindow3.open(map, marker3);
        });
    }

    $(".c-shipping-address-radio").on('click', function () {

        var shippingFields = $("[name='address[shipping][fullname]'],[name='address[shipping][email]'],[name='address[shipping][address1]'],[name='address[shipping][zip]'],[name='address[shipping][city]'],[name='address[shipping][country]']");

        if ($("#shipping-address-new").prop('checked') === true) {
            shippingFields.prop('required', true);
        } else {
            shippingFields.prop('required', false);
        }
    });

    $(".c-billing-address-radio").on('click', function () {

        var billingFields = $("[name='address[billing][fullname]'],[name='address[billing][email]'],[name='address[billing][address1]'],[name='address[billing][zip]'],[name='address[billing][city]'],[name='address[billing][country]']");

        if ($("#billing-address-new").prop('checked') === true) {
            billingFields.prop('required', true);
        } else {
            billingFields.prop('required', false);
        }
    });

    if ($('#quiz').length > 0) {
        $.ajax({
            url: '/assets/components/roestereivier/connector.php',
            method: "POST",
            dataType: "json",
            data: {
                action: "roestereivierajax",
                run: "quiz"
            },
            success: function (result) {

                var questions = [{
                    "key": "preparation",
                    "name": "Wie bereitest du deinen Kaffee zu?",
                    "desc": "",
                    "index": 0,
                    "sortDir": 'ASC',
                    "type": "button",
                    "values": ["French Press", "Filter oder Filtermaschine", "Siebträger", "Bialetti", "Aeropress", "Vollautomat"],
                    "error": "Du musst eine Wahl treffen!"
                }, {
                    "key": "choclet",
                    "name": "Welche Art von Schokolade schmeckt dir am besten?",
                    "desc": "",
                    "index": 1,
                    "sortDir": 'ASC',
                    "type": "radio",
                    "values": ["Vollmilchschokolade", "Nutella", "Nougat", "Zartbitterschokolade", "80% Schokolade", "Ich mag keine Schokolade"],
                    "error": "Du musst eine Wahl treffen!"
                }, {
                    "key": "roasting",
                    "name": "Magst du lieber helle oder dunkle Röstung?",
                    "desc": "",
                    "index": 2,
                    "sortDir": 'ASC',
                    "type": "range",
                    "from": "hell",
                    "to": "dunkel",
                    "values": ["1", "2", "3", "4", "5"],
                    "error": "Du musst eine Wahl treffen!"
                }, {
                    "key": "fruits",
                    "name": "Welche Art von Früchten magst du?",
                    "desc": "",
                    "index": 3,
                    "sortDir": 'ASC',
                    "type": "button",
                    "values": ["Zitrus", "Rote Beeren", "Steinfrüchte", "Bananen", "Äpfel", "Tropische Früchte"],
                    "error": "Du musst eine Wahl treffen!"
                }, {
                    "key": "drink",
                    "name": "Was ist dein Lieblingsdrink",
                    "desc": "",
                    "index": 4,
                    "sortDir": 'ASC',
                    "type": "radio",
                    "values": ["Old Fashioned", "Altbier", "Weisswein", "Rotwein", "Gin Tonic", "Perlwein"],
                    "error": "Du musst eine Wahl treffen!"
                }, {
                    "key": "spice",
                    "name": "Welche Gewürze schmecken dir am ehesten?",
                    "desc": "",
                    "index": 5,
                    "sortDir": 'ASC',
                    "type": "button",
                    "values": ["Zimt", "Pfeffer", "Curry", "Basilikum", "Salz", "Muscat"],
                    "error": "Du musst eine Wahl treffen!"
                }, {
                    "key": "adventure",
                    "name": "Wie abenteuerlustig bist du? ",
                    "desc": "",
                    "index": 6,
                    "sortDir": 'ASC',
                    "type": "radio",
                    "values": ["Zu Hause ist es schön und sicher", "Abenteuer sind ok, aber nur mit Navi", "Je ungewisser der Pfad desto größer mein Bock"],
                    "error": "Du musst eine Wahl treffen!"
                }];

                var quiz = $('#quiz').jQuiz({
                    "questions": questions,
                    "answers": result.results.json,
                    "active": "is--active",
                });
            }
        });
    }

    $(document).on('click', '.addquizcart', function (event) {
        event.preventDefault();

        $('#modal').addClass('is--active');
        $('body').addClass('has--modal');

        $.ajax({
            url: '/assets/components/roestereivier/connector.php',
            method: "POST",
            dataType: "html",
            data: {
                action: "roestereivierajax",
                run: "modal",
                id: $(this).attr('data-id'),
            }
        }).done(function (result) {

            $('#modal').addClass('is--loaded');
            $('#modal  .modal-content').append(result);

            $('[data-jselect="true"]').jSelect();


            $('#modal .modal-content').formHandling();


        });
    });

    $(document).on('click', '.css-icon--cross', function () {
        $('#modal .modal-content').empty();
        $('body').removeClass('has--modal');
        $('#modal').removeClass('is--active');
        $('#modal').removeClass('is--loaded');
    });


    $(document).ready(function () {
        $('.animated-label input').each(function () {
            if ($(this).val() == '') {
                $(this).parents('.animated-label').removeClass('is--filled');
            } else {
                $(this).parents('.animated-label').addClass('is--filled');
            }
        });

        $('.animated-label input').on('focusin', function () {
            $(this).parents('.animated-label').addClass('has--focus');
        });

        $('.animated-label input').on('focusout', function () {
            $(this).parents('.animated-label').removeClass('has--focus');
        });

        $('.animated-label input').on('change', function () {

            if ($(this).val() == '') {
                $(this).parents('.animated-label').removeClass('is--filled');
            } else {
                $(this).parents('.animated-label').addClass('is--filled');
            }
        });

    });

    /* MICROSITE */
    $('.toggle-password').on('click', function () {

        if ($(this).prev('input').attr('type') === 'password') {
            $(this).prev('input').attr('type', 'text');
        } else {
            $(this).prev('input').attr('type', 'password');
        }
    });

    $.extend($.validator.messages, {
        required: "Pflichtfeld",
        remote: "Bitte füll dieses Feld aus",
        email: "Please enter a valid email address.",
        url: "Please enter a valid URL.",
        date: "Please enter a valid date.",
        dateISO: "Please enter a valid date (ISO).",
        number: "Please enter a valid number.",
        digits: "Please enter only digits.",
        creditcard: "Please enter a valid credit card number.",
        equalTo: "Please enter the same value again.",
        accept: "Please enter a value with a valid extension.",
    });

    $('#address').validate({
        rules: {
            firstname: 'required',
            lastname: 'required',
            address1: 'required',
            zip: 'required',
            city: 'required',
            email: {
                required: true,
                email: true,
            },
            password: {
                minlength: 6,
                required: true,
            },
            password_confirm: {
                required: true,
                equalTo: '#password'
            },
            legalNote: 'required',

        },
        messages: {
            legalNote: ' ',
            email: 'Bitte gib eine gültige E-Mail-Adresse an',
            address1: 'Bitte gib deine Straße und Hausnummer an',
            firstname: 'Bitte gib Deinen Vornamen an',
            lastname: 'Bitte gib Deinen Nachnamen an',
            password: 'Bitte gib ein Passwort ein (min. 6 Zeichen)',
            password_confirm: {
                required: 'Bitte bestätige Dein Passwort',
                equalTo: 'Deine Passwörter stimmen nicht überein'
            }
        },
        errorElement: 'span',
        errorClass: 'has--error',
        validClass: 'is--valid',
        errorPlacement: function (error, element) {
            $(element).parent().addClass(this.settings.errorClass);
            $(element).parent().append(error);
        },
        success: function (error, element) {
            $(element).parent().removeClass(this.errorClass);
        },
        submitHandler: function () {
            $("[data-r4-prime='true']").data('r4Prime').checkIfUserExists();
        },
        highlight: function (element, errorClass) {
            $(element).parent().addClass(this.settings.errorClass);
            // $(element).parent().append($('<span />').addClass(this.settings.errorClass).text());
        }
    });

    var checkplz = $('#checkplzhero');
    var checkplzBtn = $('#checkplzherobtn');

    checkplz.on('keyup', function (event) {
        if (event.keyCode === 13) {
            checkplzBtn.trigger('click');
        } else if ($(event.target).val().length == 5) {
            checkplzBtn.prop('disabled', false);
        } else {
            checkplzBtn.prop('disabled', true);
        }
    });

    checkplzBtn.on('click', function () {
        $("[data-r4-prime='true']").data('r4Prime').checkZipCode(checkplz.val());
    });


// Logo Slider
    $('.logo-slider').slick({
        dots: true,
        infinite: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

});
