/* eslint-disable */
import jQuery from 'jquery';

if (!jQuery) {
    throw new Error("jCanvas requires jQuery")
}
(function (a, e, n, s) {
    var o = function (e) {
        var n = this;
        n.$elem = a(e);
        n.metadata = {}
    };
    o.prototype = {
        defaults: {
            active: "is--open",
            classname: "jcanvas",
            closeclass: "jcanvas--close",
            containerclass: "jcanvas--container",
            overlayclass: "jcanvas--overlay",
            overlayactiveclass: "has--overlay",
            position: "top",
            overlay: true
        }, init: function () {
            var e = this;
            e.config = a.extend({}, e.defaults, e.options, e.$elem.get(0).dataset);
            e.id = e.$elem.attr("id");
            e.$elem.addClass("off " + e.config.classname + " " + e.config.containerclass);
            e.$elem.addClass("is--" + e.config.position);
            e.btn = a(n).find('[data-jcanvas-btn="' + e.id + '"]');
            if (e.config.overlay) {
                e.appendOverlay()
            }
            e.registerEvents();
            return e
        }, reInit: function () {
            var a = this;
            a.init()
        }, registerEvents: function () {
            var n = this;
            n.btn.on("click.jcanvas touch.jcanvas", a.proxy(function (a) {
                a.preventDefault();
                n.toggleCanvas()
            }, n));
            n.$elem.find("." + n.config.closeclass).on("click.jcanvas touch.jcanvas", a.proxy(function (a) {
                a.preventDefault();
                n.toggleCanvas()
            }, n));
            a(e).on("resize.jcanvas", a.proxy(function (a) {
                n.closeCanvas()
            }, n))
        }, destroy: function () {
            var a = this;
            a.btn.off(".jcanvas");
            a.$elem.removeClass(a.config.classname);
            a.$elem.removeClass(a.config.containerclass);
            a.$elem.removeClass("is--" + a.config.position);
            a.$elem.removeClass(a.config.active);
            a.btn.removeClass(a.config.active);
            a.overlay.remove();
            a.$elem.removeData()
        }, toggleCanvas: function () {
            var a = this;
            if (a.$elem.hasClass(a.config.active)) {
                a.closeCanvas()
            } else {
                a.openCanvas()
            }
        }, openCanvas: function () {
            var e = this;
            e.$elem.trigger("onBeforeOpenCanvas", [e]);
            e.$elem.addClass(e.config.active);
            e.btn.addClass(e.config.active);
            a("body").addClass(e.config.overlayactiveclass);
            if (e.config.overlay) {
                e.overlay.addClass(e.config.overlayactiveclass)
            }
            e.$elem.trigger("onOpenCanvas", [e])
        }, closeCanvas: function () {
            var e = this;
            e.$elem.trigger("onBeforeCloseCanvas", [e]);
            e.$elem.removeClass(e.config.active);
            e.btn.removeClass(e.config.active);
            a("body").removeClass(e.config.overlayactiveclass);
            if (e.config.overlay) {
                e.overlay.removeClass(e.config.overlayactiveclass)
            }
            e.$elem.trigger("onCloseCanvas", [e])
        }, appendOverlay: function () {
            var e = this;
            e.overlay = a("<div/>").addClass(e.config.overlayclass);
            a("body").append(e.overlay);
            e.overlay.on("click.jcanvas touch.jcanvas", a.proxy(function (a) {
                e.toggleCanvas()
            }, e))
        }, removeOverlay: function () {
            var a = this;
            a.overlay.off(".jcanvas");
            a.overlay.remove()
        }
    };
    a.fn.jCanvas = function () {
        return this.each(function () {
            var e = new o(this).init();
            a(this).data("jCanvas", e)
        })
    };
    a(n).ready(function () {
        a("[data-jcanvas='true']").jCanvas()
    })
})(jQuery, window, document);
