/* eslint-disable */
import jQuery from 'jquery';
/*
 * r4PrimeZipCodes
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.1 – Initial version.
 */

// throw exeption if no jQuery
if (!jQuery) {
    throw new Error("r4PrimeZipCodes requires jQuery");
}

;(function ($, window, document, undefined) {

    // our plugin constructor
    var r4PrimeZipCodes = function (elem) {
        var me = this;

        me.$elem = $(elem);

    };

    // the plugin prototype
    r4PrimeZipCodes.prototype = {
        defaults: {},


        init: function () {
            var me = this;

            // Introduce defaults that can be extended either globally or using an object literal.
            me.config = $.extend({}, me.defaults, me.options, me.$elem.get(0).dataset);

            me.$checkplz = me.$elem.find('input');
            me.$checkplzBtn = me.$elem.find('button');
            me.$reset = me.$elem.find('#checkplzReset');

            me.ziplist = me.config.zip.split(',');

            me.registerEvents();

            return me;
        },

        registerEvents: function() {
            var me = this;

            me.$checkplz.on('keyup', $.proxy(function (event) {
                if($(event.target).val().length == 5) {
                    me.$checkplzBtn.prop('disabled', false);
                } else {
                    me.$checkplzBtn.prop('disabled', true);
                }

                if(event.keyCode === 13) {
                    me.checkZipCode(me.$checkplz.val());
                }
            }, me));

            me.$checkplzBtn.on('click', $.proxy(function () {
                // check plz
                me.checkZipCode(me.$checkplz.val());
            }, me));

            me.$reset.on('click', $.proxy(function (event) {
                event.preventDefault();

                // check plz
                me.$checkplz.val('');
                $('#checkplzForm').removeClass('is--hidden');
                $('#checkplzError').addClass('is--hidden');

            }, me));
        },

        checkZipCode: function(zip) {
            var me = this;

            if(me.ziplist.indexOf(zip) >= 0) {
                window.location.href = me.config.url + '?checkplzhero=' + zip;
            } else {
                $('#checkplzForm').addClass('is--hidden');
                $('#checkplzError').removeClass('is--hidden');
            }
        }
    };

    $.fn.r4PrimeZipCodes = function () {
        return this.each(function () {
            var plugin = new r4PrimeZipCodes(this).init();
            $(this).data('r4PrimeZipCodes', plugin);
        });
    };

    /**
     * Self ini plugin.
     */
    $(document).ready(function () {
        $("[data-r4-prime-zip='true']").r4PrimeZipCodes();
    });

})(jQuery, window, document);
