/* eslint-disable */
import jQuery from 'jquery';
/*
 * jCoffeProduct
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.1 – Initial version.
 */

// throw exeption if no jQuery
if (!jQuery) {
    throw new Error("jCoffeProduct requires jQuery");
}

;(function ($, window, document, undefined) {

    // our plugin constructor
    var jCoffeProduct = function (elem) {
        var me = this;

        me.$elem = $(elem);

    };

    // the plugin prototype
    jCoffeProduct.prototype = {
        defaults: {
            classes: {
                variant: '#jVariant',
                flourToggle: '#jFlourToggle',
                flour: '#jFlour',
                qty: '#jQty',
                add2cart: '#jAdd2cart',
                upto: '#jPriceUpto',
                price: '#jPrice',
            }
        },


        init: function () {
            var me = this;

            // Introduce defaults that can be extended either globally or using an object literal.
            me.config = $.extend({}, me.defaults, me.options, me.$elem.get(0).dataset);

            me.$variant = $(me.config.classes.variant, me.$elem);
            me.$flourToggle = $(me.config.classes.flourToggle, me.$elem);
            me.$flour = $(me.config.classes.flour, me.$elem);
            me.$qty = $(me.config.classes.qty, me.$elem);
            me.$add2cart = $(me.config.classes.add2cart, me.$elem);
            me.$upto = $(me.config.classes.upto, me.$elem);
            me.$price = $(me.config.classes.price, me.$elem);

            me.$formComment = $('[name="comment"]', me.$elem);
            me.$formQty = $('[name="qty"]', me.$elem);

            me.$variantSelect = $('[data-jselect="true"]', me.$variant);
            me.$flourToggleSelect = $('[data-jselect="true"]', me.$flourToggle);
            me.$flourSelect = $('[data-jselect="true"]', me.$flour);
            me.$qtySelect = $('[data-jselect="true"]', me.$qty);

            me.registerEvents();

            return me;
        },


        /**
         * Register all plugin events.
         */
        registerEvents: function() {
            var me = this;

            // toggle variants
            me.$variantSelect.on('change', $.proxy(function(event, inst, value){

                //toggle price

                if(value === '') {
                    // reset
                    me.reset();
                } else {
                    // change price
                    me.setPrice(me.$variantSelect.find('[value="'+value+'"]').attr('data-price-formatted'));

                    // show next
                    me.$flourToggle.removeClass('is--hidden');
                }

            }, me));

            // toggle flour
            me.$flourToggleSelect.on('change', $.proxy(function(event, inst, value){
               if(parseInt(value) === 1) {
                   // show next
                   me.$flourSelect.data('jSelect').reset();
                   me.$flour.removeClass('is--hidden');
                   me.disableAdd2Cart();
               } else {
                   // show buy button
                   me.enableAdd2Cart();
                   me.$flour.addClass('is--hidden');
                   me.$formComment.val('Ganze Bohne');
               }

            }, me));

            // flour
            me.$flourSelect.on('change', $.proxy(function(event, inst, value){

               if(value === '') {
                    // hide buy button
                   me.disableAdd2Cart();
               } else {
                   // show buy button
                   me.enableAdd2Cart();
                   me.$formComment.val(value);
               }

            }, me));


            // select
            me.$qtySelect.on('change', $.proxy(function(event, inst, value){
                me.$formQty.val(value);
            }, me));
        },

        reset: function() {
            var me = this;

            me.$flourToggle.addClass('is--hidden');
            me.$flour.addClass('is--hidden');

            me.$flourToggleSelect.data('jSelect').reset();
            me.$flourSelect.data('jSelect').reset();

            me.disableAdd2Cart();
            me.setPrice(0);
        },

        enableAdd2Cart: function() {
            var me = this;

            me.$qtySelect.data('jSelect').enableSelect();
            me.$add2cart.prop('disabled', false);
        },

        disableAdd2Cart: function() {
            var me = this;

            me.$qtySelect.data('jSelect').disableSelect();
            me.$add2cart.prop('disabled', true);
        },

        setPrice: function(price) {
            var me = this;

            if(price === 0 || price === '') {
                me.$upto.removeClass('is--hidden');
                me.$price.html(me.getLowestPrice());
            } else {
                me.$upto.addClass('is--hidden');
                me.$price.html(price);
            }
        },

        /**
         * Get loewest price from variant select list.
         *
         * @returns {*}
         */
        getLowestPrice: function() {
            var me = this;

            var p = 0;
            me.$variantSelect.find('option').each(function(index, elem) {
                if($(elem).attr('data-price') > p) {
                    p = $(elem).attr('data-price');
                }
            });

            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(p / 100).replace(' ', '&thinsp;');
        }
    };

    $.fn.jCoffeProduct = function () {
        return this.each(function () {
            var plugin = new jCoffeProduct(this).init();
            $(this).data('jCoffeProduct', plugin);
        });
    };

    /**
     * Self ini plugin.
     */
    $(document).ready(function () {
        $("[data-coffe-product='true']").jCoffeProduct();
    });

})(jQuery, window, document);
