/* eslint-disable */
import jQuery from 'jquery';
/*
 * jFullPage
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.1 – Initial version.
 */

// throw exeption if no jQuery
if (!jQuery) {
    throw new Error("jFullPage requires jQuery");
}

;(function ($, window, document, undefined) {

    // our plugin constructor
    var jFullPage = function (elem) {
        var me = this;

        me.$elem = $(elem);
    };

    // the plugin prototype
    jFullPage.prototype = {
        defaults: {
            active: 'is--active',
            delay: 250,         // delay time of window resize event firing
            magnetic: true,     // fit page to viewport during scrolling
            hash: true,         // set location hash based on page id
        },

        init: function () {
            var me = this;

            // Introduce defaults that can be extended either globally or using an object literal.
            me.config = $.extend({}, me.defaults, me.options, me.$elem.get(0).dataset);

            // optimize resizing event
            me.throttled = false;

            // pages
            me.$pages = me.$elem.children();

            // page
            me.$window = $(window);
            me.$body = $('html, body');

            // mark as active
            me.$elem.addClass(me.config.active);

            // set basic height
            me.setSectionHeight();

            // index values
            me.index = 0;
            me.currentIndex = null;
            me.currentPosition = 0;

            // is click event
            me.isClick = false;

            // hash
            me.hash = '';

            //
            me.event = '';

            // set index
            me.setIndex();

            me._appendDots(me);

            // register events
            me.registerEvents();

            // got püage if hash is set
            // me.goToPageByHash();

            return me;
        },

        /**
         * Re initialise off canvas.
         */
        reInit: function () {
            var me = this;

            me.init();
        },

        /**
         * Register all plugin events.
         */
        registerEvents: function () {
            var me = this;

            // register all toggle buttons
            me.$window.on('resize.jFullPage', $.proxy(function (event) {
                if (!me.throttled) {
                    me.setSectionHeight();

                    me.throttled = true;

                    setTimeout(function () {
                        me.throttled = false;
                    }, me.config.delay);
                }

            }, me));

            // set new index
            me.$window.on('scroll.jFullPage', $.proxy(function (event) {

                me.event = 'scroll';

                if (!me.isClick) {
                    me.setIndex();

                    me.isScrollChange = true;

                    if (me.index !== me.currentIndex && me.index < me.max) {
                        $('li', me.$dots).removeClass(me.config.active);
                        $('li', me.$dots).eq(me.index).addClass(me.config.active);
                        me.currentIndex = me.index;
                        me.setCurrentHash();
                    }
                }

            }, me));

            // click nav
            me.$elem.on('click.jFullPage', '.fullpage--dots li', $.proxy(function (event) {
                me.event = 'click';

                me.$body.stop();

                me.isClick = true;

                me.index = $(event.target).index();

                if (me.index !== me.currentIndex) {
                    me.goToPage(me.index);
                }

            }, me));

            // change hash
            $(window).on('hashchange', function () {
                // me.goToPageByHash();

                me.event = 'hashchange';

            });

        },

        /**
         * Destroy plugin and unregister all events.
         */
        destroy: function () {
            var me = this;

            // unbind all events
            me.$window.off(".jFullPage");

            // remove height
            me.$elem.children().height('auto');

            // remove dots
            me.$dots.remove();

            // remove all data
            me.$elem.removeData();
        },

        /**
         * Set each page element to window height if css vh not supported.
         */
        setSectionHeight: function () {
            var me = this;

            me.height = $(window).height();

            if ($(me.$pages[0]).height() !== me.height) {
                me.$elem.children().not('.fullpage--auto-height').height(me.height);
            }
        },

        /**
         * Set index of current page.
         */
        setIndex: function () {
            var me = this;

            var i = Math.round(($(document).scrollTop() / me.height) + 0.3, 10);
            me.max = me.$pages.length;

            if (i > me.max) i = 0;

            me.index = i;
        },

        /**
         * Scroll to page of given index.
         *
         * @param index
         */
        goToPage: function (index) {
            var me = this;

            me.currentPosition = ($(me.$pages[index]).offset().top);

            if (index < me.max && index !== me.currentIndex) {
                me.$body.animate({scrollTop: me.currentPosition}, 800, 'linear', function () {
                    me.isClick = false;

                    // window.scrollTo(0, me.currentPosition);

                    if (me.config.hash === true) {
                        me.setCurrentHash();
                    }
                });
            }

            me.currentIndex = index;

            // set active nav
            me.$dots.children().removeClass(me.config.active);
            $(me.$dots[me.currentIndex]).addClass(me.config.active);
            $(me.$dots.children().get(me.currentIndex)).addClass(me.config.active);
        },

        /**
         * Set new hash to browser location.
         *
         */
        setCurrentHash: function () {
            var me = this;

            if (me.config.hash === false) return;

            var h = $(me.$pages[me.currentIndex]).attr('id');

            if (h !== window.location.hash && typeof h !== 'undefined' && (h !== "")) {
                history.pushState('Page ' + h, window.location.href.split('#', 1).toString() + '#' + h, "#" + h);
                me.hash = h;
            }
        },

        goToPageByHash: function () {
            var me = this;


            if (me.config.hash === false) return;

            var h = window.location.hash;

            if (h !== me.hash && typeof h !== 'undefined') {
                // get index
                var elem = $(h, me.$elem);

                if (elem.length === 1 && elem.index()) {

                }

            }
        },


        _appendDots: function (me) {

            if (me.$pages.length === 1) return;

            me.$dots = $('<ul />').addClass('fullpage--dots');

            $.each(me.$pages, function (index, elem) {
                var dot = $('<li />').attr('data-index', index).text(index + 1);

                if (index === me.index) dot.addClass(me.config.active);

                dot.appendTo(me.$dots);
            });

            me.$dots.appendTo(me.$elem);
        }

    };

    $.fn.jFullPage = function () {
        return this.each(function () {
            var plugin = new jFullPage(this).init();
            $(this).data('jFullPage', plugin);
        });
    };

    /**
     * Self ini plugin.
     */
    $(document).ready(function () {
        $("[data-fullpage='true']").jFullPage();
    });

})(jQuery, window, document);
