/* eslint-disable */
import jQuery from 'jquery';
/*
 * formHandling – Collapse / toogle elements
 * Author: Dimitri Preiß
 * Further changes, comments: Dimitri Preiß
 * Licensed under the GNU GENERAL PUBLIC LICENSE v3
 *
 * version 0.1 – Initial version.
 */

// throw exeption if no jQuery
if (!jQuery) { throw new Error("formHandling requires jQuery"); }

;(function( $, window, document, undefined ){

    // our plugin constructor
    var formHandling = function( elem ){
        var me = this;

        me.$elem = $(elem);

    };

    // the plugin prototype
    formHandling.prototype = {
        defaults: {
            active: 'is--active',

        },

        init: function () {
            var me = this;

            me.config = $.extend({}, me.defaults, me.options, me.$elem.get(0).dataset);

            me.shopVariantDetail = $('[name="shop--variant-detail"]', me.$elem);
            me.shopVariant = $('[name="shop--variant"]', me.$elem);
            me.shopQty = $('[name="add-quantity"]', me.$elem);
            me.shopFlourToggle = $('#jFlourToggle', me.$elem);
            me.shopFlour = $('#jFlour', me.$elem);
            me.shopTax = $('#jtax', me.$elem);
            me.btnAddtocart = $('[name="btn-addtocart"]', me.$elem);
            me.coffee = (me.shopFlour.length > 0 && me.shopFlourToggle.length > 0);

            // Events
            me.registerEvents();


            return me;
        },


        /**
         * Re initialise off canvas.
         */
        reInit: function() {
            var me = this;

            me.init();
        },

        /**
         * Register all plugin events.
         */
        registerEvents: function() {
            var me = this;

            me.shopVariantDetail.on('change', $.proxy(function(event,a,b,c){

                if(me.shopVariant.length > 0 && c !== "" && me.shopVariant.parent().data('jSelect').getValue() !== "") {
                    me.shopQty.parent().data('jSelect').enableSelect();
                    me.btnAddtocart.prop('disabled', false);
                } else if(me.shopVariant.length === 0 && c !== "" && me.shopVariantDetail.parent().data('jSelect').getValue() !== "") {
                    me.shopQty.parent().data('jSelect').enableSelect();
                    me.btnAddtocart.prop('disabled', false);
                } else {
                    me.shopQty.parent().data('jSelect').disableSelect();
                    me.btnAddtocart.prop('disabled', true);
                }
            }, me));

            me.shopVariant.on('change', $.proxy(function(event,a,b,c){

                $('.shop--price', me.$elem).html($('option:selected',me.shopVariant).attr('data-price-formatted'));
                $('.shop--price-upto', me.$elem).hide();

                if(c === "" && me.shopVariantDetail.length > 0 && me.shopVariantDetail.parent().data('jSelect').value === "") {
                    $('.shop--price', me.$elem).html($('.shop--price', me.$elem).attr('data-from'));
                    $('.shop--price-upto', me.$elem).show();
                }

                if(c !== "" && me.shopVariantDetail.length > 0 && me.shopVariantDetail.parent().data('jSelect').value !== "") {
                    me.shopQty.parent().data('jSelect').enableSelect();
                    me.btnAddtocart.prop('disabled', false);
                } else {
                    me.shopQty.parent().data('jSelect').disableSelect();
                    me.btnAddtocart.prop('disabled', true);
                }

                if(me.shopVariantDetail.length === 0 ) {
                    me.shopQty.parent().data('jSelect').enableSelect();
                    me.btnAddtocart.prop('disabled', false);
                }
            }, me));
        },

        /**
         * Destroy plugin and unregister all events.
         */
        destroy: function() {
            var me = this;
        },
    };

    $.fn.formHandling = function() {
        return this.each(function() {
            var plugin = new formHandling(this).init();
            $(this).data('formHandling', plugin);
        });
    };

    /**
     * Self ini plugin.
     */
    $(document).ready(function() {
        $("[data-collapse='true']").formHandling();
    });

})( jQuery, window , document );
